<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      <div v-html="selectItem.title"></div>
    </v-card-title>
    <v-card-text>
      <v-chip x-small v-if="selectItem.type" :color="notifyTypeColor[selectItem.type]">{{ $t(`labels.notify_type_${selectItem.type}`)}}</v-chip>
      <div v-html="selectItem.content" style="white-space: pre-line;"></div>
    </v-card-text>
    <v-card-text v-if="selectItem.images">
      <div class="mb-2">Hình ảnh:</div>
      <v-avatar class="ma-1" size="50px"
                v-for="(img, key) in getNotifyImages(selectItem.images)"
                :key="`notify_img_${key}`"
                style="border: 1px solid #9e9e9e; border-radius: 5px"
                @click="showImages(selectItem.images, key)">
        <img :src="img">
      </v-avatar>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="closeDialog">
        Đóng
      </v-btn>
      <!--<v-btn color="success darken-1" text v-if="selectItem.status === 0 && isShowReadBtn" @click="readOne">
        Đã đọc
      </v-btn>-->
    </v-card-actions>
  </v-card>
</template>

<script>
import {api as viewerApi} from "v-viewer";
import {httpClient} from "@/libs/http";
import {NOTIFY_TYPE_COLOR} from "@/libs/const";

export default {
  name: "NotifyDetail",
  props: {
    selectItem: {
      type: Object,
      default: () => {}
    },
    isShowReadBtn: {
      type: Boolean,
      default: () => true
    }
  },
  data:() => ({
    countDown: 2,
    isLoading: false,
    notifyTypeColor: {...NOTIFY_TYPE_COLOR}
  }),
  mounted() {
    // this.countdownForRead()
    this.readOne()
  },
  methods: {
    countdownForRead () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countdownForRead()
        }, 1000)
      }
    },
    getNotifyImages(images) {
      if (images) {
        images = images.split(';')
        return images.map(img => (`${process.env.VUE_APP_FILE_CDN_URL}/${img}`))
      }
      return []
    },
    showImages(images, index) {
      if (!images) {
        return false
      }
      index = index || 0
      images = this.getNotifyImages(images)
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index
        },
        images
      })
    },
    closeDialog() {
      this.$emit('closeDialog')
    },
    refreshData(count) {
      this.$emit('refreshData')
      this.$emit('onNotifyChange', count)
    },
    readOne() {
      const ids = [this.selectItem.id]
      this.readNotify(ids)
    },
    async readNotify(ids) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/notification-read', {ids})
        this.isLoading = false
        // this.closeDialog()
        this.refreshData(data)
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
      }
    },
  }
}
</script>

<style scoped>

</style>
